const dateTimeFormat = {
  date: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
  time: {
    day: "numeric",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  datetime: {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  shortweektime: {
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
  },
  clock: {
    hour: "numeric",
    minute: "numeric",
  },
} as const;

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  datetimeFormats: {
    af: dateTimeFormat,
    ar: dateTimeFormat,
    bg: dateTimeFormat,
    ca: dateTimeFormat,
    cs: dateTimeFormat,
    da: dateTimeFormat,
    de: dateTimeFormat,
    dum: dateTimeFormat,
    el: dateTimeFormat,
    en: dateTimeFormat,
    es: dateTimeFormat,
    fi: dateTimeFormat,
    fr: dateTimeFormat,
    he: dateTimeFormat,
    hu: dateTimeFormat,
    it: dateTimeFormat,
    ja: dateTimeFormat,
    ko: dateTimeFormat,
    ku: dateTimeFormat,
    nl: dateTimeFormat,
    no: dateTimeFormat,
    pl: dateTimeFormat,
    pt: dateTimeFormat,
    ro: dateTimeFormat,
    ru: dateTimeFormat,
    srcyrl: dateTimeFormat,
    sv: dateTimeFormat,
    tr: dateTimeFormat,
    uk: dateTimeFormat,
    zhS: dateTimeFormat,
    zhT: dateTimeFormat,
  },
}));
